/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */
import 'reflect-metadata';

import 'symbol-observable';
/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
// import 'classlist.js';  // Run `npm install --save classlist.js`.

/**
 * Web Animations `@angular/platform-browser/animations`
 * Only required if AnimationBuilder is used within the application and using IE/Edge or Safari.
 * Standard animation support in Angular DOES NOT require any polyfills (as of Angular 6.0).
 */
// import 'web-animations-js';  // Run `npm install --save web-animations-js`.

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 * because those flags need to be set before `zone.js` being loaded, and webpack
 * will put import in the top of bundle, so user need to create a separate file
 * in this directory (for example: zone-flags.ts), and put the following flags
 * into that file, and then add the following code before importing zone.js.
 * import './zone-flags';
 *
 * The flags allowed in zone-flags.ts are listed here.
 *
 * The following flags will work for all browsers.
 *
 * (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
 * (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
 * (window as any).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames
 *
 *  in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 *  with the following flag, it will bypass `zone.js` patch for IE/Edge
 *
 *  (window as any).__Zone_enable_cross_context_check = true;
 *
 */

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
// import 'zone.js/dist/zone';  // Included with Angular CLI.

/***************************************************************************************************
 * APPLICATION IMPORTS
 */
function getAllShadowRootElements(parent: HTMLElement): ShadowRoot[] {
  const allElements = Array.from(parent.querySelectorAll('*')) as any[];

  const shadowElements = allElements
    .filter(x => x.shadowRoot)
    .map(x => x.shadowRoot);

  const resultList = [];
  for (const shadowElement of shadowElements) {
    resultList.push(shadowElement, ...getAllShadowRootElements(shadowElement));
  }

  return resultList;
}


function getXpath(xpath: string, possibleShadowDomHosts: string[]): string[] {
  const segments = xpath.split('/');
  const finalPath = [];

  let currentSegmentPart = '';
  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    if (!segment) {
      if (i > 0 ) {
        currentSegmentPart += '/';
      }
      continue;
    }

    currentSegmentPart += `/${segment}`;

    if (possibleShadowDomHosts.includes(segment)) {
      finalPath.push(currentSegmentPart);
      currentSegmentPart = '';
    }
  }
  finalPath.push(currentSegmentPart);
  return finalPath;
}

function convertToCssSelector(xPath: string): string {
  let selector = '';
  let filterOpenedAt: boolean|number = false;
  let squareBracketsOpened = 0;
  for (let i = 0; i < xPath.length; i++) {
    if (xPath[i] === '/') {
      if (i === 0 || i === 1) {
        continue;
      }

      if (xPath[i + 1] === '/') {
        i++;
        selector += ' ';
      } else {
        selector += ' > ';
      }
      continue;
    }

    if (xPath[i] === '[') {
      if (filterOpenedAt === false) {
        filterOpenedAt = i;
      }

      squareBracketsOpened++;
    }

    if (xPath[i] === ']') {
      squareBracketsOpened = Math.max(0, squareBracketsOpened - 1);
      if (!squareBracketsOpened) {
        filterOpenedAt = false;
      }
    }

    if (xPath[i] === '@' && filterOpenedAt === i - 1) {
      continue;
    }
    selector += xPath[i];
  }


  return selector;
}

((oldHandler) => {
  const newHandler = (xpathExpression, contextNode, namespaceResolver, resultType, result) => {
    const actualContextNode = contextNode;
    const allShadowDomElements = getAllShadowRootElements(contextNode);
    const allShadowDomElementNames = Array.from(new Set(allShadowDomElements
      .map(x => x.host.tagName.toLowerCase())));

    const webComponentBasedXPath = getXpath(xpathExpression, allShadowDomElementNames);
    if (webComponentBasedXPath.length === 1) {
      return oldHandler.apply(document, [
        xpathExpression,
        contextNode,
        namespaceResolver,
        resultType,
        result
      ]);
    }

    try {
      for (const pathPart of webComponentBasedXPath) {
        const cssSelector = convertToCssSelector(pathPart);
        const possibleNode = contextNode.querySelector(cssSelector);
        if (possibleNode.shadowRoot) {
          contextNode = possibleNode.shadowRoot;
        } else {
          contextNode = possibleNode;
        }
      }

      return oldHandler.apply(document, [
        `.`,
        contextNode,
        namespaceResolver,
        resultType,
        result
      ]);
    } catch {
      const evaluatedResult = allShadowDomElements.find(shadowRootedElement => {
        try {
          return document.evaluate(xpathExpression, shadowRootedElement, namespaceResolver, resultType, result);
        } catch {
          return null;
        }
      });

      return evaluatedResult || oldHandler.apply(document, [
        xpathExpression,
        actualContextNode,
        namespaceResolver,
        resultType,
        result]);
    }
  };
  document.evaluate = newHandler;
})(document.evaluate);
